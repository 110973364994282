import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, StaticImage, withArtDirection } from 'gatsby-plugin-image';
import React from 'react';
import address from '../../images/address.png';
import design from '../../images/home-hero/design.png';
import bgMob from '../../images/home-hero/dr-bg-mob.svg';
import bg from '../../images/home-hero/dr-bg.svg';
import ellipse from '../../images/home-hero/ellipse.svg';
import { SecondaryBtn } from '../Buttons/Secondary';
import { padding } from '../layout/GlobalStyles';
import { PrimaryBtn } from './../Buttons/Primary';

const Section = styled.section`
    position: relative;

    @media (min-width: 1024px) {
        padding-top: 166px;
        display: flex;
        justify-content: center;
        padding-bottom: 166px;
    }

    @media (min-width: 1800px) {
        ::before {
            content: url(${design});
            position: absolute;
            left: 1px;
            bottom: -8px;
        }
    }

    @media (min-width: 2000px) {
        padding: 166px calc((100vw - 1810px) / 2);
        justify-content: space-between;
    }
`;

const DesktopBox = styled.div`
    display: none;

    @media (min-width: 1024px) {
        display: block;
        max-width: 460px;
        margin: 84px -100px 0 3.125vw;
        z-index: 5;

        h1 {
            margin: 0;
        }

        a {
            display: block;
        }

        a:first-of-type {
            margin: 32px 0;
            width: 90%;
            max-width: 620px;
            color: ${({ theme }) => theme.darkTeal};
            display: flex;

            > img {
                margin-right: 18px;
                align-self: flex-start;
            }
        }

        a:nth-of-type(2) {
            width: max-content;
            margin-bottom: 24px;
        }

        a:last-of-type {
            width: max-content;
        }
    }

    @media (min-width: 1280px) {
        max-width: 515px;

        a:nth-of-type(2) {
            display: inline-block;
            margin: 0 32px 0 0;
        }

        a:last-of-type {
            display: inline-block;
        }
    }

    @media (min-width: 1440px) {
        max-width: 645px;

        a {
            display: inline-block;
        }

        a:nth-of-type(2) {
            margin-right: 64px;
        }
    }

    @media (min-width: 1800px) {
        margin-right: -200px;
        margin-left: 6vw;
    }

    @media (min-width: 1900px) {
        margin-top: 128px;
    }

    @media (min-width: 2300px) {
        margin-left: 0;
    }
`;

const ImgContainer = styled.div`
    position: relative;

    @media (min-width: 1600px) {
        ::after {
            content: url(${ellipse});
            position: absolute;
            right: 17%;
            bottom: -163px;
        }
    }
`;

const Dr = styled.div`
    display: inline-flex;
    align-items: center;
    background: url(${bgMob}) no-repeat;
    padding-left: 15px;
    min-height: 117px;
    min-width: 309px;
    background-size: cover;
    position: absolute;
    left: 16px;
    bottom: 0;
    filter: drop-shadow(0px 32px 64px rgba(33, 163, 185, 0.2));

    h3,
    p {
        color: ${({ theme }) => theme.bg};
        margin: 0;
        font-weight: 700;
    }

    p span {
        display: none;
    }

    @media (max-width: 325px) {
        flex-direction: column;
        background-size: cover;
        padding-right: 16px;
    }

    @media (min-width: 1024px) {
        background: url(${bg});
        background-size: cover;
        min-width: 359px;
        min-height: 167px;
        filter: drop-shadow(64px 64px 128px rgba(33, 163, 185, 0.5));
        left: -111px;

        h3 {
            margin-bottom: 8px;
        }
    }

    @media (min-width: 1600px) {
        h3 {
            font-size: 1.4rem;
        }

        p {
            font-size: 1rem;
        }
    }

    @media (min-width: 1800px) {
        padding-left: 22px;
        min-width: 450px;
        min-height: 209px;
        bottom: 40px;
        left: -200px;

        > div:last-of-type {
            > p > span {
                display: block;
            }
        }

        h3 {
            font-size: 1.5rem;
        }

        p {
            span {
                display: inline;
            }
        }
    }

    @media (min-width: 1900px) {
        min-width: 527px;
        min-height: 245px;
        left: -230px;
        bottom: 120px;
    }
`;

const Box = styled.div`
    ${padding};
    padding: 56px 16px 30px;
    margin-top: -40px;
    position: relative;
    overflow: hidden;

    h1 {
        margin: 0;
    }

    @media (min-width: 1024px) {
        display: none;
    }
`;

export default function HomeHero() {
    const data = useStaticQuery(graphql`
        query heroImages {
            mobile: file(relativePath: { eq: "home-hero/hero-mob.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 558, height: 505, quality: 100)
                }
            }
            tablet: file(relativePath: { eq: "home-hero/hero.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 730, height: 644, quality: 100)
                }
            }
            largeDesktop: file(relativePath: { eq: "home-hero/hero.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 1100, height: 970, quality: 100)
                }
            }
            drMob: file(relativePath: { eq: "home-hero/dr-mob.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 64, height: 80, quality: 100)
                }
            }
            dr: file(relativePath: { eq: "home-hero/dr.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 174, height: 204, quality: 100)
                }
            }
        }
    `);

    const heroImages = withArtDirection(getImage(data.largeDesktop), [
        {
            media: '(max-width: 767px)',
            image: getImage(data.mobile),
        },
        {
            media: '(min-width: 768px) and (max-width: 1600px)',
            image: getImage(data.tablet),
        },
    ]);

    const drImages = withArtDirection(getImage(data.dr), [
        {
            media: '(max-width: 1023px)',
            image: getImage(data.drMob),
        },
    ]);

    return (
        <Section>
            <DesktopBox>
                <h1>Have the best smile on the Island.</h1>
                <a href="#contact-us">
                    <img src={address} alt="address" /> 5030 Anchor Way Suite 8, 2nd Floor, Antilles
                    Bldg, Gallows Bay Christiansted, VI 00820
                </a>
                <PrimaryBtn as="a" href="#contact-us">
                    Book An Appointment
                </PrimaryBtn>
                <SecondaryBtn as={Link} to="/contact-us/">
                    Contact Us
                </SecondaryBtn>
            </DesktopBox>

            <ImgContainer>
                <GatsbyImage
                    image={heroImages}
                    alt="child"
                    loading="eager"
                    css={css`
                        margin-left: auto;
                        mix-blend-mode: darken;
                        min-width: 558px;
                        max-width: 558px;
                        min-height: 505px;
                        display: block;

                        @media (min-width: 768px) and (max-width: 1439px) {
                            max-width: 730px;
                            max-height: 644px;
                        }

                        @media (min-width: 1440px) {
                            max-width: 800px;
                            max-height: 706px;
                        }

                        @media (min-width: 1800px) {
                            max-width: 916px;
                            max-height: 800px;
                        }

                        @media (min-width: 1900px) {
                            max-width: 1102px;
                            max-height: 973px;
                        }
                    `}
                />

                <Dr>
                    <GatsbyImage
                        image={drImages}
                        loading="eager"
                        alt="dr herbert land"
                        css={css`
                            margin-right: 8px;
                            width: 64px;
                            height: 80px;

                            @media (min-width: 1024px) {
                                width: 110px;
                                height: 129px;
                            }

                            @media (min-width: 1800px) {
                                margin-right: 24px;
                                width: 142px;
                                height: 166.5px;
                            }

                            @media (min-width: 1900px) {
                                width: 174px;
                                height: 204px;
                            }
                        `}
                    />

                    <div>
                        <h3>Dr. Herbert Land</h3>
                        <p>
                            Board Certified <span>Orthodontist</span>
                        </p>
                    </div>
                </Dr>
            </ImgContainer>
            <Box>
                <h1>Best smile on the Island.</h1>
                <PrimaryBtn
                    as="a"
                    href="#contact-us"
                    css={css`
                        margin: 16px 0;
                        display: block;
                        width: max-content;
                    `}
                >
                    Book An Appointment
                </PrimaryBtn>
                <SecondaryBtn
                    as={Link}
                    to="/contact-us/"
                    css={css`
                        display: inline-block;
                    `}
                >
                    Contact Us
                </SecondaryBtn>

                <StaticImage
                    src="../../images/home-hero/mob-bg.png"
                    alt=""
                    loading="eager"
                    placeholder="none"
                    layout="fullWidth"
                    css={css`
                        position: absolute;
                        inset: 0;
                        z-index: -1;
                        filter: blur(1000px);

                        @media (min-width: 1024px) {
                            display: none;
                        }
                    `}
                />
            </Box>
            <StaticImage
                src="../../images/home-hero/desktop-bg.png"
                alt=""
                loading="eager"
                placeholder="none"
                layout="fullWidth"
                css={css`
                    display: none;
                    position: absolute;
                    inset: 0;
                    z-index: -1;

                    @media (min-width: 1024px) {
                        display: block;
                    }
                `}
            />
        </Section>
    );
}
